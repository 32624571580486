<script>
import ResponseMessage from "@/components/ResponseMessage.vue";
import MelaregaloInvoice from "@/components/melaregalo/MelaregaloInvoice.vue";

import { mask } from "vue-the-mask";
//import get from "lodash/get";

export default {
  name: "BillingInformation",
  props: {
    smartCart: { type: Object, required: true }
  },
  components: {
    ResponseMessage,
    MelaregaloInvoice
  },
  data() {
    return {
      userData: null,
      supportData: {
        privacy1: true,
        privacy2: true,
        country: {
          itemId: null
        }
      },
      tmpList: { type: Array, required: true },
      response: {
        info: "Hai selezionato MelaRegalo per un importo totale di "
      }
    };
  },
  mixins: [],
  directives: { mask },
  methods: {},
  created() {
    //console.log(this.tmpList);
    // console.log(this.props.allItemsList);
  },
  watch: {},
  async mounted() {
    //  await this.getUserData();
    // this.validate();
  }
};
</script>

<style lang="scss">
.value-and-quantity {
  .row {
    border-bottom: 1px solid var(--v-grey-lighten2);
    .label .price {
      font-size: 16px;
    }
  }

  .custom-text {
    color: var(--v-primary-base) !important;
  }
}
</style>

<template>
  <div class="value-and-quantity custom-text">
    <div
      class="list-detail-container d-flex flex-column scroll-container white rounded-md custom-text"
    >
      <h2 class="primary--text font-weight-bold pl-3 pl-sm-0 py-3 py-sm-0">
        {{ $t("melaregalo.billingInformation.title") }}
      </h2>
      <br />

      <h3>
        <span>
          {{ $t("melaregalo.billingInformation.subtitle") }}
        </span>
      </h3>

      <MelaregaloInvoice
        :smartCart="smartCart"
        :isShowBtnSave="false"
        class="custom-text"
      />
    </div>

    <div>
      <ResponseMessage class="mt-3" :response="response" />
    </div>
  </div>
</template>
