<template>
  <v-row class="awards flex-column" v-if="category && category.store">
    <v-container class="melaregalo">
      <category-block
        :target="category"
        position="position1"
        class="category-block category-block-1"
      />
      <v-stepper v-model="step" elevation="0" flat class="pa-2 my-4" alt-labels>
        <div class="d-flex justify-space-between align-center">
          <CategoryTitle :category="category" />

          <img
            class="mr-2"
            width="130"
            src="/img_layout/melaregalo/icon-melaregalo.png"
            alt="MelaRegalo"
          />
        </div>

        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            Valore e quantità
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2">
            Recapiti e informazioni di contatto
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
            Checkout e pagamento
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <ValueAndQuantity
              :allItemsList="allItemsList"
              :smartCart="listData"
              :documentLoaded="documentLoaded"
              v-if="productList && productList.length"
            />
            <br /><br />
            <a href="/page/regolamento-melaregalo" target="_blank">
              <span class="primary--text">
                {{ $t("melaregalo.generalRules") }}</span
              >
            </a>
          </v-stepper-content>

          <v-stepper-content step="2">
            <BillingInformation :smartCart="listData" v-if="step == 2" />
          </v-stepper-content>

          <v-stepper-content step="3">
            <CheckoutPayment
              :isFisicalCard="isFisicalCard"
              :smartCart="listData"
              v-if="step == 3"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <div class="pa-2 my-4 v-stepper__content">
        <br />
        <br />
        <div
          class="d-flex justify-center align-center"
          style="text-align: center;background-color:#c8c3e8; height: 50px;"
        >
          <p class="mb-0">
            Per qualsiasi richiesta o necessità non esitare a contattarci. Invia
            una mail a <strong>melaregalo@tigros.it</strong>.
          </p>
        </div>
        <br /><br />

        <v-layout
          :column="$vuetify.breakpoint.xs"
          :row="$vuetify.breakpoint.smAndUp"
          class="align-sm-center"
        >
          <v-row
            no-gutters
            justify="space-between"
            justify-sm="start"
            class="mt-5 mt-sm-0"
          >
            <v-col cols="5">
              <v-btn
                v-if="showButtonBack()"
                x-large
                depressed
                block
                class="primary--text"
                v-html="$t('common.back')"
                @click="backStep"
              />
            </v-col>
            <v-col cols="2"></v-col>

            <v-col cols="5">
              <v-btn
                v-if="showButtonNext()"
                color="primary"
                x-large
                depressed
                block
                v-html="$t('common.next')"
                @click="nextStep"
                :disabled="disableButtonNextValue"
              />
            </v-col>
          </v-row>
        </v-layout>
      </div>
    </v-container>

    <!-- || (hasCustomizeCard && !hasFileSelected) -->

    <v-divider class="mx-3 my-5"></v-divider>
    <template v-if="totItems > 0">
      <v-col cols="12" class="awards-products">
        <v-btn
          color="primary"
          block
          v-intersect="onIntersect"
          :loading="loading"
          @click="loadMore"
          v-if="$vuetify.breakpoint.xs && hasMoreItems"
        >
          <template v-if="hasMoreItems">{{ $t("products.showMore") }}</template>
          <template v-else>{{ $t("products.showAll") }}</template>
        </v-btn>
        <v-pagination
          v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
          :value="pageFilter"
          :page="pager.selPage"
          :length="pager.totPages ? pager.totPages : 0"
          :totalVisible="7"
          @next.stop.prevent="handlePageFilter"
          @prev="handlePageFilter"
          @input="handlePageFilter"
          class="py-6"
          prev-icon="$chevronLeft"
          next-icon="$chevronRight"
          color="primary"
        ></v-pagination>
      </v-col>
    </template>
    <div v-else-if="loading" class="product-list-grid-skeleton">
      <v-row>
        <v-col
          v-for="index in 12"
          :key="index"
          cols="12"
          sm="6"
          md="6"
          lg="3"
          xl="4"
        >
          <v-skeleton-loader
            type="image, list-item-three-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <span v-else>{{ $t("awards.products.noProducts") }}</span>
  </v-row>
</template>

<style lang="scss">
.awards {
  &-header {
    > div {
      flex: 1 1 50%;
      &:nth-child(2) {
        height: fit-content;
      }
    }
  }
  &-filter {
    .v-input__control {
      display: flex;
      align-items: center;
      .v-input__append-inner {
        margin-top: 0 !important;
        align-self: center;
      }
    }
  }
  &-products {
    .action-button {
      display: none;
    }
  }
  .theme--light.v-sheet {
    background-color: #ffffff;
    border-color: #ffffff;
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
  }
}
.melaregalo {
  .v-stepper__header {
    .v-stepper__label {
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        font-size: 11px;
        width: 70%;
      }
    }
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ValueAndQuantity from "@/components/melaregalo/ValueAndQuantity.vue";
import BillingInformation from "@/components/melaregalo/BillingInformation.vue";
import CheckoutPayment from "@/components/melaregalo/CheckoutPayment.vue";
import categoryMixins from "~/mixins/category";
import ProductService from "~/service/productService";
import TigrosCustomService from "@/service/tigrosCustomService";
//import AwardsDetail from "@/components/melaregalo/AwardsDetail.vue";
//import ListDetail from "@/components/lists/ListDetail.vue";
import clickHandler from "~/mixins/clickHandler";
import downloadDocument from "~/mixins/downloadDocument";
import { mapGetters } from "vuex";
import tigrosCustomService from "@/service/tigrosCustomService";

export default {
  name: "Awards",
  props: {
    hideFilterString: { type: String, required: false }
  },
  components: {
    ValueAndQuantity,
    BillingInformation,
    CheckoutPayment,
    CategoryTitle
  },
  mixins: [categoryMixins, clickHandler, downloadDocument],
  data() {
    return {
      disableButtonNextValue: false,
      savedBillingAddress: false,
      step: 1,
      listData: {},
      productList: [],
      allItemsList: [],
      facets: [],
      wordSearched: null,
      searchText: null,
      filteredProductsCount: 0,
      pager: {},
      page: 1,
      key: 0,
      listId: null,
      loading: false,
      hasFileSelected: false,
      hasCustomizeCard: false,
      isFisicalCard: true,
      documentLoaded: false
    };
  },

  computed: {
    ...mapGetters({
      user: "cart/getUser",
      pageFilter: "category/pageFilter"
    }),

    totItems() {
      return this.pager && this.pager.totItems ? this.pager.totItems : 0;
    },
    hasMoreItems() {
      if (this.pager) {
        return this.pager.totPages > this.pager.selPage;
      } else {
        return false;
      }
    },
    facetsFilter: {
      get() {
        return this.$store.state.category.filters.facets;
      },
      set() {
        // this.$store.commit("app/updatedDrawerLeft", value);
      }
    },
    isWishListItemPresent() {
      return this.listData && this.listData.cartItems.length > 0;
    }
  },
  methods: {
    disableButtonNext(data) {
      var result = false;

      if (this.step == 1) {
        if (this.listData && this.listData.totalPrice > -1) {
          result = !(this.listData.totalPrice > 0);
        }
      } else if (this.step == 2) {
        if (
          data == undefined ||
          data == null ||
          data.personTypeId == undefined ||
          data.personTypeId == null ||
          data.personTypeId == "null"
        ) {
          if (
            this.listData.personTypeId == undefined ||
            this.listData.personTypeId == null ||
            this.listData.personTypeId == "null"
          ) {
            result = true;
          } else {
            result = false;
          }
        } else {
          result = false;
        }
      }

      this.disableButtonNextValue = result;

      //return result;
    },
    showButtonBack() {
      var result = false;
      if (this.step > 1) {
        result = true;
      } else {
        result = false;
      }

      return result;
    },
    showButtonNext() {
      var result = false;
      if (this.step < 3) result = true;
      else result = false;

      return result;
    },

    nextStep() {
      this.$vuetify.goTo(0);

      if (this.step == 2) {
        global.EventBus.$emit("update_billing_address_melaregalo");
        if (this.savedBillingAddress) {
          this.step++;
        }
      } else if (this.step == 1) {
        if (this.hasFileSelected && this.hasCustomizeCard) {
          global.EventBus.$emit("submitDocument");
        }
        this.step++;
      } else {
        if (this.step == 3) {
          this.listData.warehouse = {};
        }
        this.step++;
      }
    },

    successBillingAddress() {
      this.savedBillingAddress = true;
      this.step++;
    },
    errorBillingAddress() {
      this.savedBillingAddress = false;
    },

    backStep() {
      this.step--;
      if (this.step < 3) {
        this.savedBillingAddress = false;
      }
      if (this.step == 1) {
        if (
          this.listData != undefined &&
          this.listData.cartInfos != undefined &&
          this.listData.cartInfos.WISHLIST_INFO &&
          this.listData.cartInfos.WISHLIST_INFO.length > 0
        ) {
          this.hasFileSelected = true;
          this.hasCustomizeCard = true;
          this.documentLoaded = true;
        }
      }
      this.disableButtonNext(null);
    },
    async plus(newQuantity, item) {
      let res = null;
      if (!item.itemId) {
        let productData = {
          product: item.product,
          quantity: newQuantity
        };
        res = await tigrosCustomService.addProductsToList([productData]);
      } else {
        res = await tigrosCustomService.updateProductsInList(
          7,
          item,
          newQuantity
        );
      }

      if (res) {
        this.listData = res;
      }
      this.disableButtonNext();
    },
    async minus(newQuantity, item) {
      let res = await tigrosCustomService.updateProductsInList(
        7,
        item,
        newQuantity
      );
      if (res) {
        if (newQuantity == 0 && item.itemId > 0) {
          item.itemId = null;
        }

        this.listData = res;
      }
      this.disableButtonNext();
    },

    async handleFacetFilter(filter) {
      this.$store.dispatch("category/setFilterFacets", {
        facets: filter.facets,
        parameterName: filter.parameterName
      });
      await this.fetchAwardsProductList();
    },
    async handleFacetFilterMobile(selectedFacetsXS) {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: selectedFacetsXS
      });

      await this.fetchAwardsProductList();
    },
    async fetchAwardsProductList(append) {
      //let _this = this;
      this.loading = true;
      console.log("this.category", this.category);
      console.log("this.categoryId", this.categoryId);
      let res = await ProductService.search(
        {
          store_id: this.category?.store.storeId,
          //parent_category_id: this.category?.categoryId,
          parent_category_id: this.categoryId || 148032236,
          q: this.wordSearched,
          page: this.pageFilter,
          page_size: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
          sort: this.sortFilter
        },
        this.facetsFilter
      );
      if (this.barcode && res.products.length > 0) {
        this.$router.push({
          name: "AwardsDetail",
          params: { slug: res.products[0].slug }
        });
      } else if (append) {
        this.products = [...this.products, ...res.products];
      } else {
        this.products = res.products;
      }

      if (res && res.products) {
        this.productList = res.products;
        this.allItemsList = [];
        if (res.products && res.products.length > 0) {
          for (let index = 0; index < res.products.length; index++) {
            const element = res.products[index];
            this.allItemsList[index] = { product: element, quantity: 0 };
          }
        }
      }
      this.key++;
      this.pager = res.page;
      this.loading = false;
      this.facetsFilter;
      this.facets = res.facets;
      this.filteredProductsCount = res.page.totItems;
    },
    async fetchList() {
      console.log("fetchList");
      this.listData = await TigrosCustomService.getList(7, false);
      if (this.step == 3) this.listData.warehouse = null;
      console.log("fetchList this.listData", this.listData);
      if (
        this.listData != undefined &&
        this.listData.cartInfos != undefined &&
        this.listData.cartInfos.WISHLIST_INFO &&
        this.listData.cartInfos.WISHLIST_INFO.length > 0
      ) {
        this.hasFileSelected = true;
        this.hasCustomizeCard = true;
        this.documentLoaded = true;
      }
    },
    async handleFreeSearch(searchText) {
      this.wordSearched = searchText;
      await this.fetchAwardsProductList();
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMoreItems) {
        this.$store.dispatch("category/setFilterPage", this.pageFilter + 1);
        this.fetchAwardsProductList(true);
      }
    },
    handlePageFilter(page) {
      var _this = this;
      this.$store.dispatch("category/setFilterPage", page);
      //await di this.fetchProduts
      this.fetchAwardsProductList();
      //oppure mettere goTo(0) dentro timeout
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    }
  },
  async mounted() {
    await this.fetchAwardsProductList();
    this.disableButtonNext();
  },
  async created() {
    this.fetchList();
    global.EventBus.$on("updatePlus", data => {
      this.plus(data.quantity, data.item);
    });

    global.EventBus.$on("updateMinus", data => {
      this.minus(data.quantity, data.item);
    });
    global.EventBus.$on("success_billing_address_melaregalo", () => {
      this.successBillingAddress();
    });
    global.EventBus.$on("error_billing_address_melaregalo", () => {
      this.errorBillingAddress();
    });
    global.EventBus.$on("hasFileSelected", value => {
      this.hasFileSelected = value;
    });
    global.EventBus.$on("customizeCard", newValue => {
      this.hasCustomizeCard = newValue;
    });
    global.EventBus.$on("check_disable_button_next", data => {
      this.disableButtonNext(data);
    });
    global.EventBus.$on("isFisicalCard", newValue => {
      this.isFisicalCard = newValue;
    });
  }
};
</script>
