<script>
import ResponseMessage from "@/components/ResponseMessage.vue";
import MelaregaloPaymentTypeList from "@/components/payment/MelaregaloPaymentTypeList.vue";

import addressService from "~/service/addressService";
import tigrosCustomService from "@/service/tigrosCustomService";

import { mask } from "vue-the-mask";
import get from "lodash/get";
import { requiredValue } from "~/validator/validationRules";
//import { mapCartInfo } from "~/service/ebsn";

export default {
  name: "CheckoutPayment",
  props: {
    smartCart: { type: Object, required: true },
    isFisicalCard: { type: Boolean, required: true }
  },
  mixins: [],
  directives: { mask },
  components: {
    ResponseMessage,
    MelaregaloPaymentTypeList
  },
  data() {
    return {
      requiredRules: requiredValue,
      selectedWarehouse: null,
      activePicker: null,
      menu: false,
      isDisabled: false,
      tmpPickupDatePreference: null,
      warehouses: [],
      paymentTypeList: [],
      isPaymentDisabled: true,
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        mainBackgroundColor: "grey lighten-3",
        titleStyle: "default--text text-uppercase payment-type-list-title",
        back: {
          label: "Torna Indietro",
          class: "paymenbt-back-button",
          show: true
        }
      },
      userData: null,
      supportData: {
        privacy1: true,
        privacy2: true,
        country: {
          itemId: null
        }
      },
      tmpList: { type: Array, required: true },
      response: {
        info: "Hai selezionato MelaRegalo per un importo totale di "
      },
      requiredWarehouse: [requiredValue("Selezionare un punto di ritiro")],
      requiredPickupDatePreference: [
        requiredValue("Selezionare preferenza data di ritiro")
      ],
      valid: false
    };
  },
  computed: {
    pickupDatePreference: {
      get() {
        let value = get(this.smartCart, "cartInfos.pickup_date_preference");
        // this.tmpPickupDatePreference = value;

        return value;
      },
      set(value) {
        if (value) {
          this.tmpPickupDatePreference = value;
          tigrosCustomService.setCartInfo(
            "pickup_date_preference",
            value,
            undefined,
            7
          );
        } else {
          this.tmpPickupDatePreference = null;
        }
      }
    }
  },
  methods: {
    searchWarehouse(data) {
      if (data) {
        for (let i = 0; i < this.warehouses.length; i++) {
          const target = this.warehouses[i];
          if (target.warehouseId == data) {
            data = target;
            break;
          }
        }
      }
      return data;
    },
    // ...mapCartInfo({
    //   warehouseId: "warehouse_id"
    // }),
    onBirthdateInput() {
      this.menu = false;
      this.validate();
    },
    async fetchWarehouses() {
      let data = await addressService.findWarehouse({});
      if (data.warehouses.length) {
        this.warehouses = data.warehouses;
      }
      console.log("fetchWarehouses -> data");

      return this.warehouses;
    },
    selectPreferWarehouse(item) {
      if (item == undefined || item == null) {
        this.smartCart.cartInfos.warehouse_id = null;
        this.isPaymentDisabled = true;
      } else {
        this.isPaymentDisabled = false;
        this.smartCart.cartInfos.warehouse_id = item;
      }

      tigrosCustomService.setCartInfo(
        "warehouse_id",
        this.selectedWarehouse,
        undefined,
        7
      );

      this.validate();
    },
    selectPreferPickerDate(item) {
      if (item == undefined || item == null || item == "") {
        this.smartCart.preferPickerDate = null;
      } else if (
        this.$dayjs()
          .add(4, "days")
          .isAfter(this.$dayjs(item))
      ) {
        this.smartCart.preferPickerDate = null;
      } else {
        this.smartCart.preferPickerDate = item;
      }

      this.pickupDatePreference = item;
    },
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          if (_this.isProfileForm) {
            _this.$emit("validate");
          }
        }
      }, 200);
    },
    actionPreConfirm() {}
  },

  async created() {
    console.log("created -> fetchWarehouses");
    await this.fetchWarehouses();
    //console.log(this.tmpList);
    // console.log(this.props.allItemsList);
  },
  watch: {},
  async mounted() {
    console.log("mounted -> fetchWarehouses");

    let value = get(this.smartCart, "cartInfos.pickup_date_preference");
    this.tmpPickupDatePreference = value;
    await this.fetchWarehouses();

    let data = get(this.smartCart, "cartInfos.warehouse_id");
    if (
      (data == undefined || data == null) &&
      (value == undefined || value == null)
    ) {
      this.isPaymentDisabled = true;
    } else {
      this.isPaymentDisabled = false;
    }
    /*

    */
    //  await this.getUserData();
    // this.validate();
  }
};
</script>

<style lang="scss">
.value-and-quantity {
  .row {
    border-bottom: 1px solid var(--v-grey-lighten2);
    .label .price {
      font-size: 16px;
    }
  }
}
</style>

<template>
  <div class="value-and-quantity">
    <div
      class="list-detail-container d-flex flex-column scroll-container white rounded-md"
      v-if="isFisicalCard"
    >
      <h2 class="primary--text font-weight-bold pl-3 pl-sm-0 py-3 py-sm-0">
        Seleziona un punto vendita di ritiro
      </h2>
      <br />
      <v-row no-gutters align="center">
        <v-col cols="12" sm="12" md="12" class="px-1">
          <v-select
            class="search-input"
            placeholder="PUNTO VENDITA DI RITIRO *"
            v-model="selectedWarehouse"
            :items="warehouses"
            required
            height="40"
            :rules="requiredWarehouse"
            outlined
            dense
            append-icon=""
            item-text="name"
            item-value="warehouseId"
            @change="selectPreferWarehouse"
          >
          </v-select>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <h2 class="primary--text font-weight-bold pl-3 pl-sm-0 py-3 py-sm-0">
            Preferenza data di ritiro
          </h2>

          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tmpPickupDatePreference"
                :placeholder="`PREFERENZA DATA DI RITIRO *`"
                required
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                :readonly="true"
                @change="selectPreferPickerDate"
              >
                <v-icon slot="append">$editcalendar</v-icon>
              </v-text-field>
            </template>
            <v-date-picker
              locale="it-IT"
              ref="picker"
              :active-picker.sync="activePicker"
              v-model="tmpPickupDatePreference"
              :min="
                $dayjs()
                  .add(4, 'days')
                  .format('YYYY-MM-DD')
              "
              type="date"
              no-title
              @change="selectPreferPickerDate"
            ></v-date-picker>
          </v-menu>
          <small
            >La data è indicativa ed è dipendente dalla conferma dell'avvenuto
            pagamento</small
          >
        </v-col>
      </v-row>
      <br />
    </div>
    <br />
    <div
      class="list-detail-container d-flex flex-column scroll-container white rounded-md"
    >
      <h2 class="primary--text font-weight-bold pl-3 pl-sm-0 py-3 py-sm-0">
        Seleziona un metodo di pagamento
      </h2>

      <v-row no-gutters align="center">
        <v-col cols="12" sm="12" md="12" class="px-1">
          <v-card color="grey lighten-3" flat class="card-4">
            <v-card-text>
              <!--TODO: gestione server side dell'invoice per pagamento selezionato -->
              <MelaregaloPaymentTypeList
                class="mt-1"
                :cart="smartCart"
                :smartCart="smartCart"
                mode="order"
                :isPaymentDisabled="isPaymentDisabled && isFisicalCard"
                :isFisicalCard="isFisicalCard"
                :options="paymentTypeListOptions"
                :defaultPaymentType="-1"
                :autoSelectPaymentType="false"
              />

              <div class="" style=" font-size: medium;">
                <strong>Entro 48 ore</strong> dall'avvenuto pagamento, riceverai
                una nostra comunicazione relativa alle tempistiche di
                consegna,indicativamente entro 7 giorni lavorativi.
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div>
      <ResponseMessage class="mt-3" :response="response" />
    </div>
  </div>
</template>
