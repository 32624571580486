<template>
  <v-list-item
    v-if="item"
    class="list-item-custom list-item px-1 px-sm-0 py-1"
    flat
  >
    <v-row align="center" class="mb-4 mb-sm-0">
      <v-col cols="5" sm="2">
        <!-- {{ item.itemId }} -->
        <v-list-item-avatar
          width="120"
          height="120"
          rounded="0"
          class="mb-0 mb-sm-2"
        >
          <img
            :src="item.product.mediaURL"
            :alt="item.product.name"
            height="70"
            onerror="this.onerror=null;this.src='/no-icon.png'"
          />
        </v-list-item-avatar>
      </v-col>
      <v-col cols="7" sm="5" class="label">
        <v-list-item-content class="default--text pa-0 pa-sm-4">
          <div class=" value-and-quantity    ">
            <v-col cols="14" class="label">
              {{ item.product.name }}
            </v-col>
          </div>
          <div class="text-uppercase text-body-2">
            {{ item.product.shortDescr }}
          </div>
        </v-list-item-content>
      </v-col>
      <v-col cols="7" sm="3" class="price">
        <template v-if="isActive">
          <div class="d-flex">
            <div
              class="qty-wrap rounded-md"
              :class="{ 'not-empty': quantity >= 0 }"
              transition="fab-transition"
              v-if="!showQntyInput"
              @dblclick="toggleShowInput"
              v-long-press="300"
              @long-press-start="toggleShowInput"
            >
              <v-btn
                tile
                aria-label="Diminuisci quantità"
                depressed
                color="grey lighten-2"
                small
                class="minus"
                @click.stop.prevent="minus(false)"
                @mousedown.stop
                v-ripple
              >
                <v-icon>$minus</v-icon>
              </v-btn>

              <div class="val-cnt">
                <span class="val">{{ quantity }} {{ unit }}</span>
                <span class="small ml-1">{{ quantityPerUnit }}</span>
              </div>
              <v-btn
                aria-label="Diminuisci quantità"
                depressed
                color="grey lighten-2"
                small
                class="plus"
                @click.stop.prevent="plus(false)"
                @mousedown.stop
                v-ripple
              >
                <v-icon>$plus</v-icon>
              </v-btn>
            </div>
            <div class="product-qty" v-else>
              <div
                class="qnty-manual-input qty-wrap d-flex align-center rounded-md"
                :class="{ 'not-empty': quantity > 0 }"
                transition="fab-transition"
              >
                <a
                  role="button"
                  class="close px-2"
                  @click.stop.prevent="toggleShowInput(false)"
                  @mousedown.stop
                >
                  <v-icon>$delete</v-icon>
                </a>
                <v-tooltip
                  bottom
                  :open-on-hover="false"
                  :open-on-click="true"
                  max-width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="qntyInput"
                      ref="manualInput"
                      class="manual-input"
                      hide-details
                      autofocus
                      outlined
                      dense
                      v-mask="'#####'"
                      type="number"
                      @click.stop.prevent=""
                      @keyup.enter="toggleShowInput(true)"
                      @keyup.esc="toggleShowInput(false)"
                      @mousedown.stop
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span
                    class="qntity-tooltip"
                    v-if="
                      this.product.productInfos.QTY_LOCKED != 'true' ||
                        (this.product.productInfos.QTY_LOCKED == 'true' &&
                          !modulus(qntyInput))
                    "
                    v-html="
                      $t('product.quantity.confirmQntity', {
                        qntyInput: qntyInput
                      })
                    "
                  >
                  </span>
                  <span
                    class="qntity-tooltip"
                    v-if="
                      this.product.productInfos.QTY_LOCKED == 'true' &&
                        modulus(qntyInput) &&
                        parseInt(qntyInput) <=
                          parseInt(this.product.productInfos.QTY_MULT)
                    "
                    v-html="
                      $t('product.quantity.editQntityMin', {
                        lowerInput: lowerInput
                      })
                    "
                  >
                  </span>

                  <span
                    class="qntity-tooltip"
                    v-if="
                      this.product.productInfos.QTY_LOCKED == 'true' &&
                        modulus(qntyInput) &&
                        parseInt(qntyInput) >
                          parseInt(this.product.productInfos.QTY_MULT)
                    "
                    v-html="
                      $t('product.quantity.editQntityMinMax', {
                        lowerInput: lowerInput,
                        higherInput: higherInput
                      })
                    "
                  >
                  </span>
                </v-tooltip>
                <a
                  role="button"
                  class="check px-2"
                  @click.stop.prevent="toggleShowInput(true)"
                  @mousedown.stop
                >
                  <v-icon>$success</v-icon>
                </a>
              </div>
            </div>
          </div>
        </template>
      </v-col>
      <v-col cols="5" sm="2">
        <v-list-item-content
          class="default--text pa-0 pa-sm-4"
          style="text-align: right;"
        >
          <div class=" value-and-quantity    ">
            <v-col cols="14" class="label">
              <strong>{{
                $n(item.grossTotal ? item.grossTotal : 0, "currency")
              }}</strong>
            </v-col>
          </div>
        </v-list-item-content>
      </v-col>
    </v-row>
  </v-list-item>
</template>
<style lang="scss">
.list-item-custom {
  .v-input__slot {
    width: 70px;
  }
  .qty-wrap {
    justify-content: center;
  }
}

.list-item-custom.list-item {
  border-bottom: 1px solid var(--v-grey-lighten1);
  .price_block {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      justify-content: left !important;
    }
  }
  .product-price {
    min-width: 140px;
  }
  .cur-price {
    font-size: 16px;
    padding: 10px;
    text-align: center;
    min-width: 105px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      min-width: auto;
    }
  }
  .price-um,
  .old-price {
    text-align: center;
  }
  .gross-total {
    font-size: 18px;
    font-weight: 700;
  }
  .qty-wrap {
    margin-left: 8px;
  }
  .add-to-cart {
    margin: 0 10px 0 5px;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      color: $white;
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      margin: 0 5px 0 3px;
    }
  }
  &:last-of-type {
    border-bottom: none;
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    .promo_discount {
      flex-direction: column !important;
    }
  }
  .promo_discount .old-price {
    margin-bottom: 8px !important;
    min-width: 105px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .qty-wrap {
      margin-left: 4px;
    }
  }
  @media (max-width: 380px) {
    .wrap-xs-column {
      flex-wrap: wrap;
    }
    .product-price {
      margin-bottom: 8px;
    }
    .qty-wrap {
      margin-left: 0px;
    }
  }
}

.value-and-quantity {
  .list-item-custom {
    .row {
      border-bottom: none;
      .label .price {
        font-size: 16px;
      }
    }
  }
}
</style>
<script>
// import CatalogService from "~/service/catalogService";
import CartService from "~/service/cartService";
import productMixin from "~/mixins/product";
import LongPress from "vue-directive-long-press";
import { mask } from "vue-the-mask";

export default {
  name: "ListItemCustom",
  components: {},
  props: ["item", "isActive", "smartCart"],

  mixins: [productMixin],
  directives: {
    "long-press": LongPress,
    mask: mask
  },
  computed: {
    product() {
      return this.item.product;
    },

    quantity() {
      // let item = this.item;

      // if (this.item === null || this.item.product === null) return 0;
      // if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
      //   //Handling floating point decimals issues
      //   var val = (this.item.weight * 100).toFixed(0);
      //   val = val * CatalogService.getMultiplier(this.item.product);
      //   val = val / 100;
      //   return val;
      // } else {
      //   return this.item.quantity;
      // }
      return this.extractQuantity();
    },
    quantityPerUnit() {
      let quantity = this.quantity;
      if (quantity === 0) return;
      if (
        this.item.product.productInfos.TIPOLOGIA === "Sfuso" &&
        this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
      ) {
        try {
          var singleUnit = Math.floor(
            quantity /
              parseFloat(
                this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
              )
          );
          var singleUnitUm = this.item.product.productInfos
            .WEIGHT_SELLING_SINGLE_UNIT_UM
            ? this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT_UM
            : "pz";
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      if (this.item.product.productInfos.TIPOLOGIA === "Confezione") {
        try {
          let singleUnit =
            quantity *
            parseFloat(this.item.product.productInfos.WEIGHT_SELLING);
          singleUnit = (singleUnit * 1000).toFixed(0);
          singleUnit = singleUnit / 1000;
          let singleUnitUm = this.item.product.productInfos.WEIGHT_UNIT_SELLING;
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      return null;
    },
    unit() {
      if (this.item.product.productInfos) {
        if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
          return this.item.product.productInfos.WEIGHT_UNIT_SELLING;
        } else {
          return this.item.product.productInfos.UNIT_SELLING != null
            ? this.item.product.productInfos.UNIT_SELLING
            : "pz";
        }
      } else {
        return null;
      }
    }
  },
  methods: {
    async toggleShowInput(save, params, isUpdateCart) {
      let _this = this;
      if (
        _this.product.productInfos.TIPOLOGIA == "Pezzo" ||
        _this.product.productInfos.TIPOLOGIA == "Confezione"
      ) {
        if (_this.showQntyInput) {
          // chiudo input
          _this.showQntyInput = false;
          if (save) {
            // confermo salvataggio qntyInput
            _this.qntyInput = parseInt(_this.qntyInput);

            _this.internalQuantity = _this.qntyInput;
            if (
              _this.item &&
              _this.item.quantity != _this.internalQuantity &&
              _this.internalQuantity > 0
            ) {
              // se ho già l'item a carrello lo setto con la nuova qty (quindi anche per minicart e checkout per siti senza carrello modificativo)

              this.plus(true);
            } else if (global.config.updateCart || isUpdateCart) {
              // solo carrello modificativo
              if (_this.item && _this.internalQuantity == 0) {
                // se esiste item e qty scelta è 0, allora cancello item dal carrello
                this.minus(true);
              } else if (!_this.item && _this.internalQuantity > 0) {
                // se non esiste item e qty > 0 -> add item se mai aggiunto al carrello

                this.plus(true);
              }
            }
          }
        } else {
          // open and init manual input
          if (global.config.updateCart) {
            // se carrello modificativo
            _this.qntyInput = _this.item ? _this.item.quantity : 0;
          } else {
            if (_this.internalQuantity >= 0) {
              // product card and detail
              _this.qntyInput = _this.internalQuantity;
            } else {
              // checkout
              _this.qntyInput = _this.item.quantity;
            }
          }
          _this.showQntyInput = true;
          _this.$nextTick(() => {
            _this.$refs.manualInput.focus();
          });
        }
      }
    },

    plus(isManualQuantity) {
      let tempQuantity = isManualQuantity
        ? this.internalQuantity
        : CartService.plus(this.item.product, this.quantity);
      var data = {
        quantity: tempQuantity,
        item: this.item
      };

      global.EventBus.$emit("updatePlus", data);
    },
    async minus(isManualQuantity) {
      let tempQuantity = isManualQuantity
        ? this.internalQuantity
        : CartService.minus(this.item.product, this.quantity);

      let res = true;
      if (tempQuantity <= 0) {
        res = await this.$dialog.confirm({
          text: global.vm.$t("message.removeItemFromList")
        });
      }
      if (res) {
        var data = {
          quantity: tempQuantity,
          item: this.item
        };
        global.EventBus.$emit("updateMinus", data);
      }
    },
    extractQuantity() {
      if (
        this.smartCart === undefined ||
        this.smartCart == null ||
        this.smartCart.length == 0
      ) {
        return 0;
      } else {
        if (this.smartCart.cartItems && this.smartCart.cartItems.length > 0) {
          for (
            let index = 0;
            index < this.smartCart.cartItems.length;
            index++
          ) {
            let element = this.smartCart.cartItems[index];
            if (element.product.id == this.item.product.id) {
              if (this.smartCart.cartItems[index].itemId) {
                this.item.itemId = this.smartCart.cartItems[index].itemId;
              }
              if (this.smartCart.cartItems[index].grossTotal) {
                this.item.grossTotal = this.smartCart.cartItems[
                  index
                ].grossTotal;
              }
              return this.smartCart.cartItems[index].quantity;
            }
          }
        }
      }
      return 0;
    }
  }
};
</script>
